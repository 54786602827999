import React from "react";
import { TideItemForUspItem } from "../../types";
import { parse } from "../../utils/component";

interface UspTileProps {
  uspTile?: TideItemForUspItem;
}

const UspTile: React.FC<UspTileProps> = ({ uspTile }) => {
  return (
    <>
      {uspTile && (
        <div className="usp-tiles__usp">
          <div className="usp-tiles__anchor">
            <div className="usp-tiles__icon">
              <i className={parse(uspTile.content?.general?.fai)} aria-hidden="true"></i>
            </div>

            <div className="usp-tiles__usp-text">
              <h4 className="usp-tiles__usp-heading">{parse(uspTile.content?.general?.title)}</h4>
              <p className="usp-tiles__usp-text">{parse(uspTile.content?.general?.text)}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UspTile;
