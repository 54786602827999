import React from "react";

interface ContactFormVoetbalreizenProps {}

const ContactFormVoetbalreizen: React.FC<ContactFormVoetbalreizenProps> = () => {
  return (
    <div className="form-iframe-container">
      <iframe
        id="zohoIframe"
        src="https://formulier.voetbalreizen.com/sportreizen/form/Contactformuliervr/formperma/0ohNLtxCVwVIHmPTIOssdGJnKp65wX0pq9v9bIUZKBg"
      ></iframe>
    </div>
  );
};

export default ContactFormVoetbalreizen;
