import React from "react";
import CardGrid from "..";
import { TideItemForImage, TideItemForImageCardGridComponent } from "../../../types";
import { getChildItemsWithTypename, parse } from "../../../utils/component";
import ImageCard from "../cards/image-card";

interface ImageCardGridProps {
  component: TideItemForImageCardGridComponent;
}

const ImageCardGrid: React.FC<ImageCardGridProps> = ({ component }) => {
  const imageComponents = getChildItemsWithTypename("TideItemForImage", component) as TideItemForImage[];

  return (
    <CardGrid
      title={parse(component.content?.general?.title)}
      description={parse(component.content?.general?.text)}
      extraClassName={"card-grid--related-sites"}
    >
      <>
        {imageComponents &&
          imageComponents
            .filter((i) => !!i.content?.general?.image)
            .map((imageComponent) => (
              <ImageCard
                key={imageComponent.id}
                image={parse(imageComponent.content?.general?.image)}
                externalLink={parse(imageComponent.content?.general?.buttonLinkExtern)}
                internalLink={parse(imageComponent.content?.general?.buttonLinkIntern)}
                linkTitle={parse(imageComponent.content?.general?.title)}
              />
            ))}
      </>
    </CardGrid>
  );
};

export default ImageCardGrid;
