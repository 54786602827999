import React from "react";
import OfferFormSportreizen from "./sportreizen";
import OfferFormVoetbalreizen from "./voetbalreizen";

interface OfferFormProps {}

const OfferForm: React.FC<OfferFormProps> = () => {
  return (
    <>
      {process.env.WEBSITE === "Sportreizen" && <OfferFormSportreizen />}
      {process.env.WEBSITE === "Voetbalreizen" && <OfferFormVoetbalreizen />}
    </>
  );
};

export default OfferForm;
