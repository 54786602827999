import React from "react";
import { Image } from "../../../../types";
import { parse } from "../../../../utils/component";
import { addWidthParameter } from "../../../../utils/image-utils";
import Link from "../../../link";

interface ImageCardProps {
  image?: Image;
  externalLink?: string;
  internalLink?: string;
  linkTitle?: string;
}

const ImageCard: React.FC<ImageCardProps> = ({ image, externalLink, internalLink, linkTitle }) => {
  return (
    <div className="relatedcard">
      <Link path={parse(internalLink)} url={parse(externalLink)} title={parse(linkTitle)} className="relatedcard__anchor">
        <img
          src={addWidthParameter(parse(image?.url), 300)}
          className="relatedcard__header-image"
          alt={parse(image?.altText)}
          title={parse(image?.title)}
        />
      </Link>
    </div>
  );
};

export default ImageCard;
