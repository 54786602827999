import React from "react";

interface OfferFormVoetbalreizenProps {}

const OfferFormVoetbalreizen: React.FC<OfferFormVoetbalreizenProps> = () => {
  return (
    <div className="form-iframe-container">
      <iframe src="https://formulier.voetbalreizen.com/sportreizen/form/ReisopMaat/formperma/x-eGXpiqb6cIwXAOF9bsXf0APMERl8U41ptb1xGI8eM"></iframe>
    </div>
  );
};

export default OfferFormVoetbalreizen;
