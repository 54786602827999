import { graphql, useStaticQuery } from "gatsby";
import { first, get, isNil, orderBy, sortBy } from "lodash";
import React, { useContext } from "react";
import { Breadcrumb } from "../../components/breadcrumb";
import BlogCardGrid from "../../components/cardgrid/blogs";
import { SquareCardItem } from "../../components/cardgrid/cards/square-card";
import FaqCardGrid from "../../components/cardgrid/faq";
import ImageCardGrid from "../../components/cardgrid/images";
import ProductCardGrid from "../../components/cardgrid/product-cardgrid";
import SquareCardGrid from "../../components/cardgrid/square-cardgrid";
import TilesCardGrid, { TileCardItem } from "../../components/cardgrid/tile-cardgrid";
import FacetedSearch from "../../components/facetedsearch";
import ContactForm from "../../components/forms/contact";
import OfferForm from "../../components/forms/offer";
import ZohoForm from "../../components/forms/zoho";
import Hero from "../../components/hero";
import Highlight from "../../components/highlight";
import Intro from "../../components/intro/wildcard";
import Reviews from "../../components/reviews";
import StaticIntro from "../../components/static-intro";
import UspTiles from "../../components/usptiles";
import { ComponentContext, ComponentContextType } from "../../contexts/component-provider";
import { FilterProvider } from "../../contexts/filter-provider";
import GlobalContext, { GlobalContextType } from "../../contexts/global-provider";
import PackageContext, { PackageContextType } from "../../contexts/package-provider";
import {
  TideItemForHeroComponent,
  TideItemForTestimonialsComponent,
  TideItemForUspComponent,
  TideItemForImage,
  TideItemForSquareCardGrid,
  TideItemForSquareCard,
  TideItemForImageCardGridComponent,
  TideItemForOverviewComponent,
  TideItemForBlogPage,
  TideItemForTitleTextComponent,
  TideItemForIntroComponent,
  TideItemForInfoBlocksComponent,
  TideItemForEvenementLocatie,
  TideItemForPage,
  TideItemForUspFolder,
  TideItemForFaqDetailPageConnection,
  TideItemForWebsite,
  TideItemForHighlightComponent,
  TideItemForFormContactComponent,
  TideItemForFormOfferComponent,
  TideItemForZohoForm,
  TideItemForHighlightEventsComponent,
  TideItemForWedstrijd,
} from "../../types";
import { getChildItemsWithTypename, parse } from "../component";
import { CreateProductCardItems } from "../package-utils";
import translate from "../translate";
import MapBlocksComponents from "./map-blocks-components";
import translations from "./translations.json";

interface MapPageComponentsProps {
  website: TideItemForWebsite;
  page: TideItemForPage;
  usps: TideItemForUspFolder;
  faqs: TideItemForFaqDetailPageConnection;
  breadcrumbs: Breadcrumb[];
  games: TideItemForWedstrijd[];
}

const MapPageComponents: React.FC<MapPageComponentsProps> = ({ website, page, usps, faqs, breadcrumbs, games }) => {
  const { countries, tournaments, teams, blogs, quickLinksNavigation } = useContext<ComponentContextType>(ComponentContext);
  const { packages, packagesLoading } = useContext<PackageContextType>(PackageContext);
  const { language, affiliate } = useContext<GlobalContextType>(GlobalContext);

  const componentRenderers = {
    TideItemForHeroComponent: (componentItem: TideItemForHeroComponent) => {
      const images = getChildItemsWithTypename("TideItemForImage", componentItem) as TideItemForImage[];
      const intro = getChildItemsWithTypename("TideItemForTitleTextComponent", componentItem) as TideItemForTitleTextComponent[];
      return (
        <Hero
          key={componentItem.id}
          image={parse(first(images)?.content?.general?.image)}
          intro={parse(first(intro))}
          breadcrumbs={breadcrumbs}
          showQsm={parse(componentItem.content?.general?.showQsm)}
          qsmSlimVersion={false}
          page={page.name}
          extraClass={!componentItem.content?.general?.showQsm ? "header--compact" : ""}
          quickLinksSection={quickLinksNavigation}
        />
      );
    },
    TideItemForHighlightEventsComponent: (componentItem: TideItemForHighlightEventsComponent) => {
      const productCardItems =
        CreateProductCardItems(packages || [], translate(translations, language, "SHOW_TRIP"), true, teams, tournaments, games, affiliate) ?? [];
      return (
        <ProductCardGrid
          cardGridTitle={parse(componentItem.content?.general?.title)}
          cardGridItems={productCardItems}
          loadingText={parse(componentItem.content?.general?.textWhileLoading)}
          emptyText={parse(componentItem.content?.general?.textWhenEmpty)}
          loading={packagesLoading}
          key={componentItem.id}
        ></ProductCardGrid>
      );
    },
    TideItemForOverviewComponent: (componentItem: TideItemForOverviewComponent) => {
      const type = first(componentItem.content?.general?.type);
      switch (type) {
        case "Blogs":
          const blogs = getChildItemsWithTypename("TideItemForBlogPage", parse(componentItem.parentItem)) as TideItemForBlogPage[];
          return <BlogCardGrid key={componentItem.id} blogs={orderBy(blogs, "content.general.date", "desc")} usePagination={true} />;
        case "Countries":
          const tileCardItems = countries.map(
            (country) =>
            ({
              title: country.content?.general?.title,
              image: country.content?.general?.flag,
              path: country.content?.general?.path ?? country.name,
            } as TileCardItem)
          );
          return <TilesCardGrid key={componentItem.id} title={parse(componentItem.content?.general?.title)} tiles={tileCardItems} />;
        case "Faq":
          return <FaqCardGrid key={componentItem.id} faqs={faqs.nodes} parentPath={parse(page.content?.general?.path)} />;
        case "Clubs":
          const locations = useStaticQuery(graphql`
            query MyQuery {
              allTideItemForEvenementLocatie {
                nodes {
                  name
                  content {
                    general {
                      id
                    }
                    address {
                      country {
                        name
                      }
                    }
                  }
                }
              }
            }
          `);
          const teamCardItems = teams
            .filter((t) => t.content?.general?.popular)
            .sort((a, b) => {
              const orderA = a.content?.general?.sortingorder;
              const orderB = b.content?.general?.sortingorder;

              if (orderA === undefined || orderA === null) return 1;
              if (orderB === undefined || orderB === null) return -1;
              return orderA - orderB;
            })
            .map(
              (team) =>
              ({
                title: team.name,
                image: team.content?.general?.logo,
                path: "clubs/" + team.content?.general?.path,
                country: (locations.allTideItemForEvenementLocatie.nodes as TideItemForEvenementLocatie[]).find(
                  (location) => location.content?.general?.id == team?.content?.general?.venueId
                )?.content?.address?.country?.name,
              } as TileCardItem)
            );
          return (
            <TilesCardGrid
              key={componentItem.id}
              tiles={sortBy(teamCardItems, ["country", "title"])}
              title={parse(componentItem.content?.general?.title)}
            />
          );
        case "Competitions":
          const tournamentCardItems = tournaments
            .filter((t) => t.content?.general?.popular)
            .sort((a, b) => {
              const orderA = a.content?.general?.sortingorder;
              const orderB = b.content?.general?.sortingorder;

              if (orderA === undefined || orderA === null) return 1;
              if (orderB === undefined || orderB === null) return -1;
              return orderA - orderB;
            })
            .map(
              (tournament) =>
              ({
                title: tournament.name,
                image: tournament.content?.general?.logo,
                path: tournament.content?.general?.path,
              } as TileCardItem)
            );
          return (
            <TilesCardGrid key={componentItem.id} tiles={tournamentCardItems} title={parse(componentItem.content?.general?.title)} />
          );
        case "Packages":
          return (
            <FilterProvider key={componentItem.id}>
              <FacetedSearch page={page.name} itemsPerPage={12}></FacetedSearch>
            </FilterProvider>
          );
        default:
          return <></>;
      }
    },
    TideItemForTestimonialsComponent: (componentItem: TideItemForTestimonialsComponent) => {
      return <Reviews key={componentItem.id} testimonials={componentItem} />;
    },
    TideItemForUspComponent: (componentItem: TideItemForUspComponent) => {
      return <UspTiles key={componentItem.id} usps={usps} uspComponent={componentItem} />;
    },
    TideItemForTitleTextComponent: (componentItem: TideItemForTitleTextComponent) => {
      return (
        <StaticIntro key={componentItem.id} title={parse(componentItem.content?.general?.title)} text={parse(componentItem.content?.general?.text)} />
      );
    },
    TideItemForImageCardGridComponent: (componentItem: TideItemForImageCardGridComponent) => {
      return <ImageCardGrid key={componentItem.id} component={componentItem} />;
    },
    TideItemForSquareCardGrid: (componentItem: TideItemForSquareCardGrid) => {
      const squareCards = getChildItemsWithTypename("TideItemForSquareCard", componentItem) as TideItemForSquareCard[];
      const cardGridItems = squareCards.map(
        (squareCard) =>
        ({
          path: squareCard.content?.general?.path,
          image: squareCard.content?.general?.image,
          title: squareCard.content?.general?.title,
          url: squareCard.content?.general?.url,
        } as SquareCardItem)
      );
      return (
        <SquareCardGrid
          key={componentItem.id}
          cardGridTitle={parse(componentItem.content?.general?.title)}
          cardGridItems={cardGridItems}
          numberOfCards={5}
        />
      );
    },
    TideItemForIntroComponent: (componentItem: TideItemForIntroComponent) => {
      const heroComponents = getChildItemsWithTypename("TideItemForHeroComponent", componentItem) as TideItemForImage[];
      return (
        <Intro
          key={componentItem.id}
          text={parse(componentItem.content?.general?.text)}
          title={parse(componentItem.content?.general?.title)}
          website={parse(website)}
          usps={parse(usps)}
          extraClassName={!heroComponents || heroComponents?.length == 0 ? "product-intro--no-header" : ""}
        />
      );
    },
    TideItemForInfoBlocksComponent: (componentItem: TideItemForInfoBlocksComponent) => {
      return <MapBlocksComponents key={componentItem.id} blocks={parse(componentItem.childItems)} games={[]} />;
    },
    TideItemForHighlightComponent: (componentItem: TideItemForHighlightComponent) => {
      return <Highlight key={componentItem.id} data={parse(componentItem.content)} blogs={blogs.slice(0, 2)} />;
    },
    TideItemForFormContactComponent: (componentItem: TideItemForFormContactComponent) => {
      return <ContactForm key={componentItem.id} />;
    },
    TideItemForFormOfferComponent: (componentItem: TideItemForFormOfferComponent) => {
      return <OfferForm key={componentItem.id} />;
    },
    TideItemForZohoForm: (componentItem: TideItemForZohoForm) => {
      return (
        <ZohoForm title={parse(componentItem.content?.general?.title)} iframeCode={parse(componentItem.content?.general?.embedCode)}>
          test zoho
        </ZohoForm>
      );
    },
  };

  return (
    <>
      {page?.childItems?.map((tideItem) => {
        const typeName = get(tideItem, "__typename");
        const renderer = get(componentRenderers, typeName);

        if (!isNil(renderer)) {
          return renderer(tideItem);
        }

        return null;
      })}
    </>
  );
};

export default MapPageComponents;

export const query = graphql`
  fragment TideItemForTestimonialsFragment on TideItemForTestimonialsComponent {
    __typename
    id
    name
    content {
      general {
        description
        reviewLinkText
      }
    }
  }
  fragment TideItemForUspComponentFragment on TideItemForUspComponent {
    __typename
    id
    name
    content {
      cta {
        path
        title
        url
      }
      general {
        title
      }
    }
    childItems {
      ... on TideItemForUspItem {
        __typename
        id
        name
        content {
          general {
            fai
            text
            title
          }
        }
      }
    }
  }
  fragment TideItemForTitleTextComponentFragment on TideItemForTitleTextComponent {
    __typename
    id
    name
    content {
      general {
        title
        text
      }
    }
  }
  fragment TideItemForImageCardGridComponentFragment on TideItemForImageCardGridComponent {
    __typename
    id
    name
    content {
      general {
        title
        text
      }
    }
    childItems {
      ... on TideItemForImage {
        __typename
        id
        name
        content {
          general {
            buttonLinkExtern
            buttonLinkIntern
            buttonText
            image {
              altText
              title
              url
            }
            title
          }
        }
      }
    }
  }
  fragment TideItemForSquareCardGridFragment on TideItemForSquareCardGrid {
    __typename
    id
    name
    content {
      general {
        title
      }
    }
    childItems {
      ... on TideItemForSquareCard {
        __typename
        id
        content {
          general {
            image {
              altText
              title
              url
            }
            path
            title
            url
          }
        }
      }
    }
  }
  fragment PageOverviewComponentFragment on TideItemForOverviewComponent {
    name
    id
    __typename
    content {
      general {
        title
        type
      }
    }
    parentItem {
      childItems {
        ... on TideItemForBlogPage {
          __typename
          id
          name
          parentNodeId
          content {
            general {
              title
              thumbnail {
                title
                altText
                url
              }
              path
              intro
              date
              tags {
                id
                name
              }
              featuredbig
              featuredimagebig {
                title
                altText
                url
              }
              featuredsmall
              featuredimagesmall {
                title
                altText
                url
              }
            }
          }
          parentItem {
            ... on TideItemForPage {
              id
              name
              content {
                general {
                  path
                }
              }
            }
          }
        }
        ... on TideItemForCountry {
          __typename
          id
          name
        }
      }
    }
  }
  fragment TideItemForHighlightComponentFragment on TideItemForHighlightComponent {
    __typename
    id
    name
    content {
      contact {
        title
        text
        image {
          title
          altText
          url
        }
      }
      general {
        title
      }
    }
  }
  fragment TideItemForHighlightEventsComponentFragment on TideItemForHighlightEventsComponent {
    __typename
    id
    name
    content {
      general {
        title
        tagId
        textWhenEmpty
        textWhileLoading
      }
    }
  }
  fragment TideItemForFormContactComponentFragment on TideItemForFormContactComponent {
    __typename
    id
    name
  }
  fragment TideItemForFormOfferComponentFragment on TideItemForFormOfferComponent {
    __typename
    id
    name
  }
  fragment TideItemForZohoFormFragment on TideItemForZohoForm {
    __typename
    id
    name
    content {
      general {
        title
        embedCode
      }
    }
  }
`;
