import React from "react";
import { Image } from "../../../../types";
import { parse } from "../../../../utils/component";
import Link from "../../../link";

interface SquareCardProps {
  squareCardItem: SquareCardItem;
}

export interface SquareCardItem {
  path?: string;
  url?: string;
  image?: Image;
  title?: string;
}

const SquareCard: React.FC<SquareCardProps> = ({ squareCardItem }) => {
  return (
    <div className="squarecard">
      <Link path={squareCardItem.path} url={squareCardItem.url} className="squarecard__anchor" title={squareCardItem.title}>
        <div className="squarecard__header">
          {squareCardItem.image && (
            <img src={parse(squareCardItem.image?.url)} className="squarecard__header-image" alt={parse(squareCardItem.image?.altText)} />
          )}
        </div>
        <div className="squarecard__body">
          <h4 className="squarecard__heading">{squareCardItem.title}</h4>
        </div>
      </Link>
    </div>
  );
};

export default SquareCard;
