import React from "react";
import CardGrid from "..";
import SquareCard, { SquareCardItem } from "../cards/square-card";

interface SquareCardGridProps {
  cardGridTitle?: string;
  cardGridItems?: SquareCardItem[];
  numberOfCards: number;
}

const SquareCardGrid: React.FC<SquareCardGridProps> = ({ cardGridItems, cardGridTitle, numberOfCards }) => {
  return (
    <CardGrid
      title={cardGridTitle}
      extraClassName={`card-grid--${numberOfCards} ${cardGridItems && cardGridItems?.length > 4 ? "card-grid--centered" : ""}`}
    >
      <>{cardGridItems && cardGridItems.map((cardGritItem, i) => <SquareCard key={i} squareCardItem={cardGritItem} />)}</>
    </CardGrid>
  );
};

export default SquareCardGrid;
