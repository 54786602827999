import React from "react";
import Link from "../../../link";

interface FaqCardProps {
  title?: string;
  icon?: string;
  path?: string;
}

const FaqCard: React.FC<FaqCardProps> = ({ title, icon, path }) => {
  return (
    <div className="faqcard">
      <Link path={`/${path}`} className="faqcard__anchor" title={title}>
        <div className="faqcard__header">
          <i className={icon} />
        </div>
        <div className="faqcard__body">
          <h2 className="faqcard__heading">{title}</h2>
        </div>
      </Link>
    </div>
  );
};

export default FaqCard;
