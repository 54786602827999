import React from "react";
import { TideItemForBlogPage, TideItemForHighlightComponentContent } from "../../types";
import { parse } from "../../utils/component";
import { addWidthParameter } from "../../utils/image-utils";
import NewsCard from "../cardgrid/cards/news-card";

interface HighlightProps {
  data?: TideItemForHighlightComponentContent;
  blogs?: TideItemForBlogPage[];
}

const Highlight: React.FC<HighlightProps> = ({ data, blogs }) => {
  return (
    <div className="blog-highlight">
      <div className="blog-highlight__container">
        <div className="blog-highlight__body">
          <div className="heading-seperator">
            <h2 className="blog-highlight__body-heading">{data?.general?.title}</h2>
          </div>

          <div className="blog-highlight__body-content">
            <div className="blog-highlight__news-cards">{blogs && blogs.map((blog, i) => <NewsCard key={i} data={blog} />)}</div>
          </div>
        </div>

        <div className="blog-highlight__sidebar">
          <div className="blog-highlight__sidebar-text">
            <div className="heading-seperator">
              <h3 className="blog-highlight__sidebar-heading">{data?.contact?.title}</h3>
            </div>
            {data?.contact?.text && <div dangerouslySetInnerHTML={{ __html: data?.contact?.text }}></div>}
          </div>
          {data?.contact?.image && (
            <img
              src={addWidthParameter(parse(data?.contact?.image?.url), 400)}
              className="blog-highlight__sidebar-image"
              alt={parse(data?.contact?.image?.altText)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Highlight;
