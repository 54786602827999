import React from "react";
import CardGrid from "..";
import { TideItemForFaqDetailPage } from "../../../types";
import FaqCard from "../cards/faq-card";
import { parse } from "../../../utils/component";

interface FaqCardGridProps {
  faqs?: TideItemForFaqDetailPage[];
  title?: string;
  parentPath?: string;
}

const FaqCardGrid: React.FC<FaqCardGridProps> = ({ faqs, title, parentPath }) => {
  return (
    <div className="faq-cards">
      <CardGrid title={title} extraClassName="card-grid--4">
        <>
          {faqs &&
            faqs.map((faq, i) => (
              <FaqCard
                key={i}
                title={parse(faq.content?.general?.title)}
                icon={parse(faq.content?.general?.icon)}
                path={parentPath + "/" + faq.content?.general?.path}
              />
            ))}
        </>
      </CardGrid>
    </div>
  );
};

export default FaqCardGrid;
