import React from "react";
import ContactFormSportreizen from "./sportreizen";
import ContactFormVoetbalreizen from "./voetbalreizen";

interface ContactFormProps {}

const ContactForm: React.FC<ContactFormProps> = () => {
  return (
    <>
      {process.env.WEBSITE === "Sportreizen" && <ContactFormSportreizen />}
      {process.env.WEBSITE === "Voetbalreizen" && <ContactFormVoetbalreizen />}
    </>
  );
};

export default ContactForm;
