import React, { useContext } from "react";
import { TideItemForBlogPage, TideItemForPage } from "../../../../types";
import Link from "../../../link";
import { parse } from "../../../../utils/component";
import translate from "../../../../utils/translate";
import translations from "../blog-card/translations.json";
import GlobalContext, { GlobalContextType } from "../../../../contexts/global-provider";
import { addWidthParameter } from "../../../../utils/image-utils";

interface NewsCardProps {
  data?: TideItemForBlogPage;
}

const NewsCard: React.FC<NewsCardProps> = ({ data }) => {
  const { language } = useContext<GlobalContextType>(GlobalContext);
  return (
    <div className="news-card">
      <Link
        path={`/${(data?.parentItem as TideItemForPage).content?.general?.path}/${data?.content?.general?.path}`}
        title={parse(data?.content?.general?.title)}
        className="news-card__wrap-anchor"
      >
        <h3 className="news-card__heading">{data?.content?.general?.title}</h3>
        <p className="news-card__timestamp">{data?.content?.general?.date}</p>
        <div className="news-card__image-wrapper">
          {data?.content?.general?.featuredimagebig && (
            <img
              src={addWidthParameter(parse(data?.content?.general?.featuredimagebig?.url), 520)}
              className="news-card__image"
              alt={parse(data?.content?.general?.featuredimagebig?.altText)}
            />
          )}
          <div className="news-card__tags">
            <div className="tags">
              {data?.content?.general?.tags &&
                data?.content?.general?.tags.map((tag, i) => (
                  <div key={i} className="tags__tag">
                    {tag?.name}
                  </div>
                ))}
            </div>
          </div>
        </div>
        {data?.content?.general?.intro && <div className="news-card__intro" dangerouslySetInnerHTML={{ __html: data.content.general.intro }}></div>}
        <span className="news-card__read-more-anchor" title={translate(translations, language, "READ_MORE")}>
          {translate(translations, language, "READ_MORE")}
        </span>
      </Link>
    </div>
  );
};

export default NewsCard;
