import React from "react";

interface ZohoFormProps {
  title?: string;
  iframeCode?: string;
}

const ZohoForm: React.FC<ZohoFormProps> = ({ title, iframeCode }) => {
  return (
    // Todo: determine if the zoho form needs a wrapper with styling
    <>{iframeCode && <div className="form-iframe-container" dangerouslySetInnerHTML={{ __html: iframeCode }}></div>}</>
  );
};

export default ZohoForm;
