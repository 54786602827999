import React from "react";
import { TideItemForUspComponent, TideItemForUspFolder, TideItemForUspItem } from "../../types";
import { getChildItemsWithTypename, parse } from "../../utils/component";
import Link from "../link";
import UspTile from "./usptile";

interface UspTilesProps {
  usps?: TideItemForUspFolder;
  uspComponent?: TideItemForUspComponent;
}

const UspTiles: React.FC<UspTilesProps> = ({ uspComponent, usps }) => {
  if (!usps?.childItems || usps.childItems.length <= 0) {
    return null;
  }
  const tiles = getChildItemsWithTypename("TideItemForUspItem", usps) as TideItemForUspItem[];

  return (
    <div className="usp-tiles">
      <div className="usp-tiles__container">
        <div className="usp-tiles__intro">
          <div className="heading-seperator">
            <h2>{parse(uspComponent?.content?.general?.title)}</h2>
          </div>
          <Link
            path={parse(uspComponent?.content?.cta?.path)}
            url={parse(uspComponent?.content?.cta?.url)}
            title={parse(uspComponent?.content?.cta?.title)}
            className="cta cta--secondary"
          >
            {parse(uspComponent?.content?.cta?.title)}
          </Link>
        </div>
        <div className="usp-tiles__usps">{tiles && tiles.length && tiles.map((tile, i) => <UspTile key={i} uspTile={tile} />)}</div>
      </div>
    </div>
  );
};

export default UspTiles;
